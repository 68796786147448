export const normalizeContentfulUseCaseNodes = (allContent) => {

  const content = allContent[0]

  return {
    hero: {
      text: [
          {
            "text": content.title,
            "color": "omegaDarker",
            "variant": "h1"
          },
          {
            "text": content.subtitle,
            "color": "omegaDark",
            "variant": "medium"
          }
        ],
        images: [
          {
            src: content.hero,
            "alt": "alt text",
            "radius": "lg",
            "shadow": "xl",
            "effects": ["fadeInLeft"]
          }
        ],
        "buttons": [
          {
            "text": "Get Started",
            "type": "PAGE",
            "link": "https://sheetgurus.com/account/signup?referrer=website_use_case",
            "variant": "primary"
          }
        ]
      },
      contentOne: {
        "collection": [
          {
            "text": [
              {
                "text": content.secondaryTitle,
                "color": "alpha",
                "variant": "h4"
              },
              {
                "text": content.secondarySubtitle,
                "color": "omegaDarker",
                "variant": "h3"
              }
            ]
          },
          {
            "text": [
              {
                "text": content.secondaryContentOne.secondaryContentOne,
                "color": "omegaDark",
                "variant": "p"
              },
              {
                "text": content.secondaryContentTwo.secondaryContentTwo,
                "color": "omegaDark"
              }
            ]
          }
        ]
      },
      }
    }
